<template lang="pug">
  b-form(@submit.stop.prevent="onSubmit")
    .box-form
      .material-icons.icon-input perm_identity
      b-form-group(
        label-for="name-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Nombre
        b-form-input#name-input.input(
          v-model="form.name"
          maxlength="256"
          :state="$v.$dirty ? !$v.form.name.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input perm_identity
      b-form-group(
        label-for="last-name-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Apellidos
        b-form-input#last-name-input.input(
          v-model="form.lastName"
          maxlength="256"
          :state="$v.$dirty ? !$v.form.lastName.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input assignment_ind
      b-form-group(
        label-for="select-role-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Seleccione Rol
        b-form-select#select-role-input.input(
          v-model="form.role"
          :options="accountOptions"
          @change="handleSelectChange"
          :state="$v.$dirty ? !$v.form.role.$invalid : null"
      )
    .box-form(v-if="typeDriver")
          .material-icons.icon-input keyboard
          b-form-group(
            label-for="code-driver-input"
            invalid-feedback="Este campo es requerido"
          )
            .input-title Código Conductor
            b-form-input#code-driver-input.input(
              v-model="form.codeDriver"
              maxlength="20"
              @input="onCodeDriverInput"
              :state="$v.$dirty ? !$v.form.codeDriver.$invalid : null"
            )
            b-form-invalid-feedback(v-if="codeDriverError" class="error-message")
              | {{ codeDriverError }}
    hr.linea
    .box-form
      .material-icons.icon-input mail_outline
      b-form-group(
        label-for="email-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Email
        b-form-input#email-input.input(
          v-model="form.email"
          maxlength="256"
          :state="$v.$dirty ? !$v.form.email.$invalid : null"
        )
    hr.linea
    .box-form(v-if="!defaultValues.role")
      .material-icons.icon-input manage_accounts
      b-form-group(
        v-if="!defaultValues.role"
        label-for="role-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Rol
        b-form-select#role-input.input(
          v-model="form.role"
          :options="roleOptions"
          :state="$v.$dirty ? !$v.form.role.$invalid : null"
        )
    hr.linea(v-if="!defaultValues.role")
    .box-form
      .material-icons.icon-input lock
      .group
      b-form-group.input-title(
        :label="`Contraseña${ editMode ? ' (opcional)' : '' }`"
        label-for="password-input"
        invalid-feedback="Este campo es requerido"
      )
        b-form-input#password-input.input(
          v-model="form.password"
          maxlength="256"
          type="password"
          :state="$v.$dirty ? !$v.form.password.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input lock
      .group
      b-form-group.input-title(
        label="Repetir contraseña"
        label-for="repeat-password-input"
        invalid-feedback="Las contraseñas no coinciden"
      )
        b-form-input#repeat-password-input.input(
          v-model="form.repeatPassword"
          type="password"
          :state="$v.$dirty ? !$v.form.repeatPassword.$invalid : null"
        )
    hr.linea
    b-checkbox(v-model="form.active") Activo
    .text-right
      b-button(type="submit" :disabled="statusSubmit")  Guardar
</template>

<script>
import roles from '../../common/roles'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  props: {
    user: {
      type: Object,
      default: null
    },
    editMode: Boolean,
    defaultValues: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      form: {
        name: this.editMode ? this.user.name : '',
        lastName: this.editMode ? this.user.lastName : '',
        active: this.editMode ? this.user.active : true,
        email: this.editMode ? this.user.email : '',
        role: this.defaultValues.role ? this.defaultValues.role : (this.editMode ? this.user.role : roles[0].value),
        password: '',
        repeatPassword: '',
        storeId: this.defaultValues.storeId ? this.defaultValues.storeId : (this.editMode ? this.user.storeId : null),
        account: '',
        codeDriver: ''
      },
      accountOptions: [
        { value: null, text: 'Seleccione', disabled: true, selected: true },
        { value: 'admin', text: 'Dueño' },
        { value: 'driver', text: 'Conductor' }
      ],
      roles: roles,
      typeDriver: false,
      statusSubmit: false,
      codeDriverError: ''
    }
  },
  validations () {
    const form = {
      name: { required },
      lastName: { required },
      email: { required, email },
      role: { required },
      password: {},
      codeDriver: this.typeDriver ? { required } : {},
      repeatPassword: { sameAs: sameAs('password') }
    }
    if (!this.editMode) {
      form.password = { required }
    }
    return { form }
  },
  methods: {
    ...mapActions(['createUser', 'updateUser', 'validateCodeDriver']),
    onSubmit () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          this.onUpdateUser()
        } else {
          this.onCreateUser()
        }
      }
    },
    handleSelectChange () {
      this.codeDriverError = ''
      if (this.form.role === 'driver') {
        this.typeDriver = true
      } else {
        this.typeDriver = false
        this.form.codeDriver = ''
      }
    },
    async onCodeDriverInput () {
      const codeDriver = this.form.codeDriver
      if (codeDriver.length >= 3) {
        const response = await this.validateCodeDriver({ params: { validateCode: codeDriver } })
        if (response.codeFind === 0) {
          this.codeDriverError = ''
          this.statusSubmit = false
        } else {
          this.codeDriverError = 'El código del conductor ya existe'
          this.statusSubmit = true
        }
      }
    },
    async onCreateUser () {
      this.statusSubmit = true
      const response = await this.createUser({ data: this.form })
      if (response.status === 201) {
        this.makeSuccessToast('El usuario se creó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear el usuario')
      }
      this.statusSubmit = false
    },
    async onUpdateUser () {
      const data = { ...this.form }
      if (data.password === '') {
        delete data.password
      }
      const response = await this.updateUser({ id: this.user.id, data })
      if (response.status === 200) {
        this.makeSuccessToast('El usuario se editó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar el usuario')
      }
    }
  },
  computed: {
    roleOptions () {
      // retrona los roles de admin y delivery de la lista de roles
      return roles.reduce((acum, role) => ['admin', 'delivery'].includes(role.value) ? acum.concat(role) : acum, [])
    }
  }
}
</script>
